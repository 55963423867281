<template>
  <div>
    <div class="flex mt-2">
            <vs-chip v-for="(intention, index) in params.data.intentions" :key="index" style="height:30px;">
      <span>{{ intention }}</span>
      </vs-chip>
          </div>
  </div>
</template>

<script>
export default {
  name: "CellRendererTags",
  computed: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>