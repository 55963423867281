<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
========================================================================================== -->

<template>
  <div id="page-leads">
    <vx-card
      ref="filterCard"
      :title="$t('Filters')"
      class="user-list-filters mb-8"
      @refresh="resetColFilters"
      refresh-content-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full hidden">
          <label class="text-sm opacity-75">{{ $t("Date") }}</label>
          <v-select
            :options="dateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="dateFilter"
            class="mb-4 sm:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Chat</label>
          <v-select
            :options="chatOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="chatFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Status</label>
          <v-select
            :options="statusOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="statusFilter"
            class="mb-4 md:mb-0"
          />
        </div>
      </div>
    </vx-card>
    <!-- Download csv -->
    <div class="flex justify-end mb-4">
      <vs-button
        color="success"
        type="filled"
        vs-justify="center"
        vs-w="12"
        @click="downloadCSV()"
        >Download CSV</vs-button
      >
    </div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">
                {{ currentPage * limit - (limit - 1) }}
                -
                {{
                  totalLeads - currentPage * limit > 0
                    ? currentPage * limit
                    : totalLeads
                }}
                {{ $t("Of") }} {{ totalLeads }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          :placeholder="$t('Search') + '...'"
        />
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <!-- ACTION - DROPDOWN -->
        <vs-dropdown vs-trigger-click class="cursor-pointer hidden">
          <div
            class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
          >
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon
                  icon="TrashIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>Delete</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>Archive</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon
                  icon="FileIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>Print</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon
                  icon="SaveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="leadsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :enableRtl="$vs.rtl"
        :localeText="localeText"
      ></ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="limit"
        v-model="currentPage"
        @click="currentPage++"
        id="leads-pagination"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

// Store Module
import moduleLeadsManagement from "@/store/leads-management/moduleLeadsManagement.js";

// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererTags from "./cell-renderer/CellRendererTags.vue";

// Translation PTBR
import localeTextPT_br from "@/store/leads-management/localeText.PT-BR.js";

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    vSelect,

    // Cell Renderer
    //CellRendererLink,
    CellRendererStatus,
    //CellRendererVerified,
    CellRendererActions,
    CellRendererTags,
  },
  data() {
    return {
      AllLeads: "",
      limit: 10,
      currentPage: 1,
      isLead: true,
      currentChat: null,

      localeText:
        this.$store.state.user.lang == "pt_BR" ||
        this.$store.state.user.lang == "pt-br"
          ? localeTextPT_br.localeText
          : "en",
      // Filter Options
      chatFilter: { label: this.$t("All"), value: null },
      chatOptions: [{ label: this.$t("All"), value: null }],

      statusFilter: { label: this.$t("LeadsOnly"), value: true },
      statusOptions: [
        { label: this.$t("LeadsOnly"), value: true },
        { label: this.$t("AllTalks"), value: null },
      ],

      dateFilter: { label: "", value: "" },
      dateOptions: [{ label: this.$t("All"), value: "all" }],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("View"),
          field: "id",
          cellRenderer: "CellRendererActions",
          width: 100,
        },
        {
          headerName: "Chat",
          field: "chat",
          width: 200,
        },
        {
          headerName: this.$t("Name"),
          field: "name",
          width: 210,
          //cellRenderer: 'CellRendererLink'
        },
        {
          headerName: this.$t("Email"),
          field: "email",
          width: 225,
        },
        {
          headerName: this.$t("Phone"),
          field: "phone",
          width: 225,
        },
        {
          headerName: "Status",
          field: "isLead",
          cellRenderer: "CellRendererStatus",
          width: 150,
        },
        {
          headerName: "Tags",
          field: "intentions",
          cellRenderer: "CellRendererTags",
          width: 200,
        },
        {
          headerName: "Tipo",
          field: "channel",
          width: 150,
        },
        {
          headerName: this.$t("Date"),
          field: "date",
          width: 150,
        },
        {
          headerName: this.$t("Hour"),
          field: "hour",
          width: 150,
        },
        {
          headerName: this.$t("Integration"),
          field: "integration",
          width: 150,
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
    };
  },
  watch: {
    limit() {
      this.getLeads();
    },
    currentPage() {
      this.getLeads();
    },
    chatFilter(obj) {
      this.currentChat = obj.value;
      this.getLeads();
    },
    statusFilter(obj) {
      this.isLead = obj.value;
      this.getLeads();
    },
    dateFilter(obj) {
      let val =
        obj.value === "all" ? "all" : obj.value == "yes" ? "true" : "false";
      this.setColumnFilter("is_verified", val);
    },
  },
  computed: {
    leadsData() {
      return this.$store.state.leadsManagement.leads;
    },
    totalLeads() {
      return this.$store.state.leadsManagement.leadsCount;
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.leadsManagement.leadsCount / this.limit
      );
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
  },
  methods: {
    async getAllLeads() {
      var payload = {
        acc: this.$store.state.acc.current_acc.id,
        chat: this.currentChat,
        //limit: this.limit,
        //page: this.currentPage,
        isLead: this.isLead,
        Lead: this.$t("Lead"),
        Talk: this.$t("Talk"),
        Complete: this.$t("Complete"),
        Incomplete: this.$t("Incomplete"),
        NotInformed: this.$t("NotInformed"),
      };
      var params = { acc: "" + payload.acc + "" };
      if (payload.chat != null) params.chat = payload.chat;
      if (payload.isLead != null) params.isLead = payload.isLead;

      const response = await this.$http.get("/p/chat/lead/list", {
        params: params,
      });
      var i = 0;
      var chatName,
        currentLead,
        currentChat = "";
      var d = "";
      var leads = [];
      var currentObjLead = {};
      if (typeof response.data.data !== "undefined") {
        if (typeof response.data.data.items !== "undefined")
          for (i = 0; i < response.data.data.items.length; i++) {
            currentLead = response.data.data.items[i];

            currentChat = response.data.data.chatsConfig.find(
              (e) => e._id === currentLead.chat
            );

            chatName = currentChat ? currentChat.name : "";

            currentObjLead = {};

            d = new Date(currentLead.createdAt);
            currentObjLead.date =
              ("0" + d.getDate()).slice(-2) +
                "/" +
                ("0" + (d.getMonth() + 1)).slice(-2) +
                "/" +
                d.getFullYear() || "";
            currentObjLead.hour =
              ("0" + d.getHours()).slice(-2) +
                ":" +
                ("0" + d.getMinutes()).slice(-2) +
                ":" +
                ("0" + d.getSeconds()).slice(-2) || "";
            currentObjLead.id = currentLead._id;
            currentObjLead.chat = chatName;
            currentObjLead.name = currentLead.name || " ";
            currentObjLead.email = currentLead.email || " ";
            currentObjLead.phone = currentLead.phone || " ";
            currentObjLead.status = currentLead.complete
              ? payload.Complete
              : payload.Incomplete;
            currentObjLead.channel =
              typeof currentLead.channel !== "undefined"
                ? currentLead.channel.charAt(0).toUpperCase() +
                  currentLead.channel.slice(1)
                : "";
            currentObjLead.intention =
              currentLead.intentions && currentLead.intentions.length > 0
                ? currentLead.intentions.join(", ") || this.$t("NoIntention")
                : this.$t("NoIntention");
            currentObjLead.url =
              currentLead.url && currentLead.uri
                ? `${currentLead.url}${currentLead.uri}`
                : "";
            currentObjLead.isLead =
              currentLead.isLead === true ? payload.Lead : payload.Talk;
            currentObjLead.integration = currentLead.integration || "Email";
            for (let k in currentLead.chatData[0]) {
              currentObjLead[k] =
                currentLead.chatData[0][k] == null ||
                typeof currentLead.chatData[0][k] === "undefined"
                  ? payload.NotInformed
                  : currentLead.chatData[0][k];
            }
            leads.push(currentObjLead);
          }
        return leads;
      }
      return null;
    },
    async downloadCSV() {
      const data = await this.getAllLeads();
      if (data) this.JSONToCSVConvertor(data, "Leads", true);
      else {
        this.$vs.notify({
          title: this.$t("GenericErrorPleaseReportTitle"),
          text: this.$t("GenericErrorPleaseReportMessage"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData =
        JSONData && typeof JSONData != "object"
          ? JSON.parse(JSONData)
          : JSONData;

      var CSV = "sep=," + "\r\n\n";

      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (let index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n";
      }

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        let row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (let index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        return;
      }

      //Generate a file name
      var fileName = "Meus_";
      //this will remove the blank-spaces from the title and replace it with an underscore
      fileName += ReportTitle.replace(/ /g, "_");

      //Initialize file format you want csv or xls
      var uri = "data:text/csv;charset=utf-8," + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      var link = document.createElement("a");
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: "" + this.$store.state.acc.current_acc.id + "",
          },
        })
        .then((response) => {
          if (this.chatOptions.length == 1) {
            this.activeChats = false;
            for (var i = 0; i < response.data.data.length; i++) {
              this.chatOptions.push({
                label: response.data.data[i].name,
                value: response.data.data[i]._id,
              });
              if (response.data.data[i].status == 1) this.activeChats = true;
              if (response.data.data[i].status == 3) this.pendingChats = true;
            }
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getLeads() {
      if (!moduleLeadsManagement.isRegistered) {
        this.$store.registerModule("leadsManagement", moduleLeadsManagement);
        moduleLeadsManagement.isRegistered = true;
      }
      this.$store
        .dispatch("leadsManagement/fetchLeads", {
          acc: this.$store.state.acc.current_acc.id,
          chat: this.currentChat,
          limit: this.limit,
          page: this.currentPage,
          isLead: this.isLead,
          Lead: this.$t("Lead"),
          Talk: this.$t("Talk"),
          Complete: this.$t("Complete"),
          Incomplete: this.$t("Incomplete"),
          NotInformed: this.$t("NotInformed"),
        })
        .then(() => {})
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["Leads"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.chatFilter =
        this.statusFilter =
        this.dateFilter =
        this.departmentFilter =
          {
            label: this.$t("All"),
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    this.getChats();
    this.getLeads();
  },
};
</script>

<style lang="scss">
#leads-pagination > div {
  justify-content: center !important;
}
#page-leads {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
