export default {
localeText: {
    // for filter panel
    page: 'Página',
    more: 'mais',
    to: 'para',
    of: 'de',
    next: 'Próximo',
    last: 'Último',
    first: 'Primeiro',
    previous: 'Anterior',
    loadingOoo: 'Carregar',

    // for set filter
    selectAll: 'Selecionar tudo',
    searchOoo: 'Procurar',
    search: 'Procurar',
    blanks: 'Vazio',

    // for number filter and text filter
    filterOoo: 'Filtrar',
    applyFilter: 'Aplicar filtro',

    // for number filter
    equals: 'Igual',
    lessThan: 'Menor que',
    greaterThan: 'Maior que',

    // for text filter
    contains: 'Contém',
    startsWith: 'Começa com',
    endsWith: 'Termina com',

    // the header of the default group column
    group: 'Grupo',

    // tool panel
    columns: 'Colunas',
    rowGroupColumns: 'Colunas do grupo de linhas',
    rowGroupColumnsEmptyMessage: 'Colunas do grupo de linhas vazias',
    valueColumns: 'Valores das colunas',
    pivotMode: 'Modo pivô',
    groups: 'Grupos',
    values: 'Valores',
    pivots: 'Pivôs',
    valueColumnsEmptyMessage: 'Valores de colunas vazias',
    pivotColumnsEmptyMessage: 'Pivôs de colunas vazias',
    toolPanelButton: 'Botão de painel de ferramentas',

    // other
    noRowsToShow: 'Não há registros para mostrar.',

    // enterprise menu
    pinColumn: 'Pinar coluna',
    valueAggregation: 'Agregar valor',
    autosizeThiscolumn: 'Redimensionar esta coluna',
    autosizeAllColumns: 'Redimensionar todas colunas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Resetar colunas',
    expandAll: 'Expandir tudo',
    collapseAll: 'Contrair tudo',
    toolPanel: 'Painel de ferramentas',
    export: 'Exportar',
    csvExport: 'Exportar para CSV',
    excelExport: 'Exportar para Excel',

    // enterprise menu pinning
    pinLeft: 'Pinar <<',
    pinRight: 'Pinar >>',
    noPin: 'Sem pinagem',

    // enterprise menu aggregation and status panel
    sum: 'Soma',
    min: 'Mínimo',
    max: 'Máximo',
    none: 'Nenhum',
    count: 'Contagem',
    average: 'Média',

    // standard menu
    copy: 'Copiar',
    copyWithHeaders: 'Copiar com cabeçalho',
    ctrlC: 'Ctrl+C',
    paste: 'Colar',
    ctrlV: 'Ctrl+V'
  }
}